export default function AllProductsCopy() {
  return [
    {
      title: 'UI Components',
      items: [
        {
          title: 'WebViewer',
          text: 'Embed viewing and editing of PDFs, Office, images in any JS framework.',
          link: '/documentation/web/get-started/'
        },
        {
          title: 'DOCX Editor',
          text: 'Embed creating and editing DOCX documents without server-side or MS Office dependencies.',
          link: '/documentation/web/guides/office/edit-docx-file/'
        },
        {
          title: 'WebViewer Video',
          text: 'Embed video and audio annotation frame-by-frame in your web application.',
          link: '/documentation/web/get-started/manually-video/'
        },
        {
          title: 'WebViewer HTML',
          text: 'Embed website annotation and markup in your web application.',
          link: '/documentation/web/guides/html/load-html/'
        },
        {
          title: 'WebViewer BIM',
          text: 'Embed IFC, RVT, OBJ, STL and DWG viewing and annotation in your web application.',
          link: '/documentation/web/guides/BIM/'
        },
        {
          title: 'Android',
          text: 'Embed viewing and editing of PDFs, Office, images with a native SDK.',
          link: '/documentation/android/'
        },
        {
          title: 'iOS',
          text: 'Embed viewing and editing of PDFs, Office, images with a native SDK.',
          link: '/documentation/ios/'
        }
      ]
    },
    {
      title: 'SDKs',
      items: [
        {
          title: 'Windows',
          text: 'Complete Document Processing SDK. Convert, edit, generate PDFs, Office documents and variety of other formats.',
          link: '/documentation/windows/'
        },
        {
          title: 'Linux',
          text: 'Complete Document Processing SDK. Convert, edit, generate PDFs, Office documents and variety of other formats.',
          link: '/documentation/linux/'
        },
        {
          title: 'Mac OS',
          text: 'Complete Document Processing SDK. Convert, edit, generate PDFs, Office documents and variety of other formats.',
          link: '/documentation/mac/'
        },
        {
          title: 'Docker',
          text: 'Complete Document Processing SDK. Convert, edit, generate PDFs, Office documents and variety of other formats.',
          link: '/documentation/web/guides/wv-server-deployment/'
        }
      ]
    },
    {
      title: 'Pre-Built Components',
      items: [
        {
          title: 'Fluent',
          text: `Supercharge document generation productivity with Fluent's powerful automation, customizable templates, and seamless integration.`,
          link: 'http://fluent.apryse.com/documentation'
        },
        {
          title: 'PDF2Data',
          text: 'UI and low-code parsing engine to automate Data extraction from pdf. Returns valuable data from pdfs in structured (JSON or XML) formats.',
          link: 'https://pdf2data.apryse.com/documentation/'
        }
      ]
    }
  ];
}
